@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Ubuntu:wght@300;400;500;700;900&display=swap);
*, *::before, *::after { box-sizing: border-box; }
html { box-sizing: border-box; }
html, body { margin: 0; padding: 0; min-height: 100%; }

body {
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 21%, rgba(223,251,254,1) 100%);
    color:#242424;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    padding:80px 0px 0px;
    margin:0 auto;
    min-height: 100%;
    line-height: 1.2;
    -webkit-font-smoothing: antialiased !important;
	text-rendering: optimizeLegibility;
	font-feature-settings: "kern";
	-webkit-font-feature-settings: "kern";
	-moz-font-feature-settings: "kern";
	-moz-font-feature-settings: "kern=1";
}

a {
    color: #0073a0;
    -webkit-text-decoration: underline #ccc;
            text-decoration: underline #ccc;
}

a:hover {
    color: #04668c;
    -webkit-text-decoration: underline #0073a0;
            text-decoration: underline #0073a0;
}

h1, h2, h3, h4, h5 {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 700;
}

main {
    width: calc(100% - 0px);
    margin: 0px auto;
    max-width:1240px;
    padding: 0 20px;
}

.wrapper {
    max-width: 1240px;
    width:100%;
    margin:0 auto;
    padding: 0 20px;
}

footer {
    position:fixed;
    left:0px;
    bottom: 0px;
    height:40px;
    line-height: 40px;
    background: rgba(255,255,255,0);
    z-index: 0;
    width:calc(100% - 0px);
    padding: 0px;
    transition: 0.3s ease;
    margin-bottom: 0px;
    clear: both;
    transition: 0.2s ease;
    border-top: 1px solid rgba(225,225,225,0);
}

footer ul {
    margin:0;
    list-style-type: none;
    padding:0px;
}

footer ul li {
    display:inline-block;
    margin-right: 15px;
}

.innskraningssida {
    margin: 0 auto;
    width:400px;
    min-height:410px;
    padding:20px;
    background-color: #fff;
    border-radius:20px;
    margin-top:30px;
}

.innskraningssida input {
    width: 100%;
    border: 1px solid #888988;
    padding:8px;
    font-size: 16px;
    margin: 5px 0;
    border-radius:6px;
    font-family: 'Open Sans', sans-serif;
}

.innskraningssida button {
    width: 100%;
    border: 1px solid #0095f9;
    background-color: #0095f9;
    color:#fff;
    padding:8px;
    font-size: 16px;
    margin: 5px 0;
    border-radius:6px;
    transition: 0.3s ease;
    cursor: pointer;
    font-family: 'Ubuntu', sans-serif;
}

.innskraningssida button:hover {
    background-color:#047ccd;
}

.innskraningssida .gleymt {
    margin:10px 0;
}

h3.login {
    font-size: 20px;
    font-weight:400;
    text-align: center;
    margin: 20px 0 0;
    color:#888988;
}

h2.login {
    font-size: 30px;
    font-weight:700;
    text-align: center;
    margin: 0px;
    color:#303030;
}

h2.nyskra {
    font-size: 30px;
    font-weight:700;
    text-align: center;
    margin: 0px 0 20px;
    color:#303030;
}

p.login {
    text-align: center;
    margin: 10px 0 30px;
}

.loginbtn {
    border: 2px solid #1297c7 !important;
    color:#fff;
    font-family: 'Ubuntu', sans-serif !important;
    font-weight: 700;
    background-color: #1297c7;
    padding:10px 0;
    transition: 0.3s ease;
    cursor: pointer;
    font-size: 20px;
}

.loginbtn:hover {
    background-color: #107aa0;

}

.brakethis {
    display: none;
  }


.buatilnotenda {
    margin-top:20px !important;
    cursor: pointer;
}

.itemfull {
    width:100%;
    background:#fff;
    margin:16px 0;
    border-radius: 8px;
    padding:24px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}

.itemfull .icon {
    height:60px;
    width:80px;
    text-align: center;
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;
}

.itemfull .info {
    min-height:60px;
    text-align: left;
    display: inline-block;
    vertical-align: top;
}

.itemfull .icon i {
    font-size: 60px;
}

.itemfull h4 {
    margin: 0;
}

.itemfull .moreInfo  {
    display:none;
}

.aukaupp {
    font-size:14px;
}

.question {
    width:100%;
    padding:10px;
}

.question label {
    display: inline-block;
}

.urgency {
    width:100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
    border: 1px solid #dedede;
    margin-bottom:20px;
}

.innskraning {
    float: right;
}

.addUrgency {
    float: right;
    display: inline-block;
    padding: 5px 10px;
    background:transparent;
    border:2px solid #0095f9;
    color:#333;
    font-size: 16px;
    text-decoration: none;
    border-radius:5px;
    transition: 0.3s ease;
}

.addUrgency:hover {
    color:#fff;
    background:#0095f9;
    text-decoration: none;
}

select, input {
    border:1px solid #999;
    background:rgba(255,255,255,0.7);
    border-radius: 5px;
    padding:5px 10px;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
}

.react-datetime-picker__wrapper {
    border:1px solid #999;
    background:rgba(255,255,255,0.7);
    border-radius: 5px;
}

.urgencies .content {
    float: right;
}

.urgency h3 {
    text-transform: uppercase;
    margin:0;
}

.urgency h4 {
    margin-top:0;
}

section .background_mynd {
    width: 45%;
    height: 500px;
    top: 0;
    right: 0;
    position: absolute;
    text-align: left;
    overflow: hidden;
    z-index: 1;
    text-align: center;
}

section .background_mynd img {
    max-width: 400px;
    margin: 100px auto 0;
}

.skra_mainwin {
    width: calc(100% - 420px);
    display: inline-block;
    vertical-align: top;
}

.skra_upplysingar {
    width: 420px;
    display: inline-block;
    vertical-align: top;
}

.buatilnotenda {
    border: 2px solid #107aa0;
    display: block;
    padding: 8px 16px;
    margin-top: -9px;
    border-radius: 8px;
    background: #fff;
    color: #107aa0;
    transition: .30s ease-in-out;
    cursor: pointer;
    font-family: 'Ubuntu', sans-serif !important;
    font-weight: 700;
    font-size: 20px;
    width:100%;
    text-align: center;
}

.buatilnotenda:hover {
    background: #107aa0;
    color:#fff;
}

h1.page-title {
    margin: 20px 0 10px;
    font-size: 28px;
}

h3.page-title {
    margin: 10px 0 10px;
    font-size: 18px;
    font-weight: 500;
}

@media only screen and (max-width: 1240px) {
    .halli_left {
        right: calc(50% + 50px);
        width: calc(50% - 50px);
    }

    .halli_right {
        left: calc(50% + 50px);
        width: calc(50% - 50px);
    }
    
} 

@media only screen and (max-width: 980px) { 
    .halli_left {
        right: calc(10% + 0px);
        width: calc(90% - 0px);
    }

    .halli_right {
        left: calc(10% + 0px);
        width: calc(90% - 0px);
    }

    .greinar {
        width:100%;
    }

    .greinar article {
        padding-right: 0;
    }
    .greinar article.grein h1 {
        font-size: 36px;
        margin: 0px 0 5px;
    }

    .greinar article.grein .sidebar {
        display:none;
    }

    .greinar article.grein .main-layout {
        width: calc(100% - 0px);
        padding-left: 0px;
    }

    .hlidarstika {
        width: 100%;
    }

    .greinar article:nth-child(2), .greinar article {
        height: auto;
        width: 100%;
        padding-right: 0px;
        margin-bottom: 30px;
    }

    .greinar figure, .greinar article:nth-child(2) figure {
        height: 200px;
        width: 100%;
    }

}

@media only screen and (max-width: 560px) { 
    .halli_left {
        right: 0%;
        width: 100%;
    }

    .halli_right {
        left: 0%;
        width: 100%;
    }

    .spilarinn .hlusta {
        display:none !important;
    }

    .spilarinn .naest {
        display:none !important;
    }

    .spilarinn .iloftinu {
        width: 100% !important;
        border-right: 0px solid #ccdeeb !important;
    }

    .spilarinn .iloftinu .spilaritexti {
        width:160px !important;
    }

    .spilarinn .iloftinu .spilari {
        width: calc(100% - 160px) !important;        
    }

    .takkar {
        width: calc(100% - 0px) !important;
    }

    .timalina {
        width: calc(100% - 0px) !important;
    }

    .ibodi img {max-width: 160px;}

    .stjornufrettir {
        width: calc(100% - 13px);
        margin-left: 13px;
    }

    .forsidufrettir .frettalisti {
        width: calc(100% - 0px);
        padding: 10px;
    }

    .auglysing_kubbur {
        width: 100%;
        margin: 20px 0 40px;
    }

    .toplisti ol {
        padding: 0;
        width: 100%;
        margin: 0 ;
    }

    .ljosipunkturinn {
        height: 310px;
        width: calc(100% - 15px);
        margin-left: 15px;
    }

    .greinar article:nth-child(2), .greinar article {
        height: auto;
        width: 100%;
        padding-right: 0px;
        margin-bottom: 30px;
    }

    .greinar figure, .greinar article:nth-child(2) figure {
        height: 200px;
        width: 100%;
    }

    .adrargreinar article, .adrargreinar article:nth-child(2) {
        width:100%;
        height: auto;
    }

    .adrargreinar figure, .adrargreinar article:nth-child(2) figure {
        width:100%;
        height:160px;
        margin-right: 20px;
    }
}

@media only screen and (max-width: 440px) {  
    .fastirlidir article {
        display: inline-block;
        width: 100%;
        text-align: center;
        margin-bottom: 40px;
    }

    .forsidufrettir .frettalisti {
        width: 100%;
        margin: 0;
    }

    .toplisti ol, .toplisti ol:last-child {
        width: 100%;
        margin: 0;
    }
}
header.header {
    position:fixed;
    left:0px;
    top: 0px;
    height:100px;
    background: rgba(255,255,255,1);
    z-index: 10;
    width:calc(100% - 0px);
    padding: 0px;
    transition: 0.3s ease;
    margin-bottom: 0px;
    clear: both;
    transition: 0.2s ease;
    border-bottom: 1px solid rgba(225,225,225,0);
}

header.header i {
    font-size: 24px;
}

header.header h1.merki {
    float: left;
    display: inline-block;
    font-size:28px;
    margin:10px 0;
}

header.header h1.merki a {
    color:#303030;
    text-decoration: none;
}

h1.logo {
    font-weight: 900;
    float: left;
    position: relative;
    height: 54px;
    line-height: 54px;
    text-indent: 64px;
    z-index: 20;
}

h1.logo a {
    color:#000;
    text-decoration:none;
}

h1.logo::before {
    position: absolute;
    left: 0px;
    top: 0;
    height: 54px;
    width: 54px;
    background: #1297c7;
    border-radius: 27px;
    content: "";
    z-index: 1;
}

#hamborgari {
    width:36px;
    height:40px;
    position:fixed;
    margin:0 auto;
    transition:.5s ease-in-out;
    cursor:pointer;
    float:left;
    top: 30px;
    right: 30px;
    z-index:103;
    display:none
}

#hamborgari:before {
    content:" ";
    height:50px;
    width:50px;
    border:5px solid rgba(200,200,200,0);
    transition:.5s ease-in-out;
    margin-left:-6px;
    margin-top:-5px;
    display:block;
    border-radius:50%
}

#hamborgari:hover:before {
    content:" ";
    height:60px;
    width:60px;
    border:5px solid rgba(200,200,200,.7);
    margin-left:-12px;
    margin-top:-10px
}

#hamborgari span {
    display:block;
    position:absolute;
    height:6px;
    width:36px;
    background:#333;
    border-radius:9px;
    opacity:1;
    left:0;
    transition:.25s ease-in-out;
    top:8px
}

#hamborgari span:nth-child(1) {
    left:0
}

#hamborgari span:nth-child(2) {
    left:0;
    top:17px;
    width:36px
}

#hamborgari span:nth-child(3) {
    left:0;
    top:26px;
    width:36px
}

#hamborgari.open span {
    background:#df1a53
}

#hamborgari.open span:nth-child(1) {
    -webkit-transform:rotate(45deg);
    transform:rotate(45deg);
    top:17px;
    left:2px;
    width:32px
}

#hamborgari.open span:nth-child(2) {
    background:#232323;
    width:0;
    left:15px
}

#hamborgari.open span:nth-child(3) {
    -webkit-transform:rotate(-45deg);
    transform:rotate(-45deg);
    top:17px;
    left:2px;
    width:32px
}


ul.menu {
    padding: 0;
    margin:44px 0 0 0; 
    float:right;
    list-style: none;
    font-family: 'Ubuntu', sans-serif !important;
    font-weight: 600;
    line-height: 20px;
}

ul.menu li {
    float: left;
    margin: 0 10px;
}

ul.menu li a {
    color:#000;
    text-decoration: none;
    font-size:18px;
    transition: .30s ease-in-out;
}

ul.menu li a:hover, ul.menu li a.selected {
    color:#1297c7;
}

ul.menu li.btn, ul.menu li.btn_inn {
    float: left;
    margin: 0 0px 0 15px;
}

ul.menu li.divider {
    border-right: 1px solid #e9e9e9;
    padding-right: 30px;
    margin-right: 14px;
}


ul.menu li.btn a {
    border:1px solid #e9e9e9;
    display: block;
    padding:8px 16px;
    margin-top: -9px;
    border-radius: 12px;
    transition: .30s ease-in-out;
}

ul.menu li.btn a:hover {
    text-decoration: none;
    background: #f9f9f9;
}

ul.menu li.btn_inn a {
    border:1px solid #107aa0;
    display: block;
    padding:8px 16px;
    margin-top: -9px;
    border-radius: 12px;
    background: #1297c7;
    color:#fff;
    transition: .30s ease-in-out;
}

ul.menu li.btn_inn a:hover {
    text-decoration: none;
    background: #107aa0;
}

ul.menu li.btn_inn.valid a {
    border:1px solid #df1a53;
    background: #df1a53;
}

ul.menu li.btn_inn.valid a:hover {
    border:1px solid #df1a53;
    background: #df1a53;
}

.fyrirsogn {
    max-width: 55%;
    font-size: 50px;
    font-weight: 900;
    margin: 0 0 20px 0;
}

.yfirfyrirsogn {
    max-width: 55%;
    font-size: 18px;
    font-weight: 300;
    color:#949494;
    margin: 100px 0 0 0;
}

.fyrirsogn span {color:#78e31c; }

.undirfyrirsogn {
    max-width: 55%;
    font-size: 18px;
}

.adgangur {
    color:#949494;
    margin: 40px 0;
}

.adgangur a {
    color:#1c78e3;
    font-weight: 500;
}

.takki {
    display: inline-block;
    padding:8px 20px;
    border-radius: 8px;
    border: 1px solid #107aa0;
    background: #1297c7;
    color:#fff !important;
    margin-right: 10px;
    font-family: 'Exo 2', sans-serif;
    font-weight: 600 !important;
    font-size: 20px;
    text-decoration: none !important;
    transition: .30s ease-in-out;
}

.takki:hover {
    text-decoration: none;
    background: #107aa0;
}

button.innskraning {
    background-color:transparent;
    border: none;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
}

button.innskraning:hover {
    color:#ab0b25;
}

@media only screen and (max-width: 980px) {
    header nav {
        display: none;
    }

    #hamborgari {
        display:block;
    }

    .vidhaekumigledinni {
        display: none;
    }

    h1.k100merki a {
        height: 47px;
        width: 110px;
    }
}
.merking {
    width:100%;
    position: relative;
    min-height:500px;
    text-align: center;
}

.merking .likami {
    height:450px;
    width:190px;
    background-image: url(/static/media/kvk.5f165ec5.svg);
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position:center;
    position: relative;
    margin: 0 auto;
}

.merking .likami.kk {
    background-image: url(/static/media/kk.44b0d956.svg);
}

.merking .likami .merki {
    height:20px;
    width:20px;
    border: 1px solid #4296c2;
    border-radius: 10px;
    background:#eafcff;
    display: inline-block;
    transition: 0.3s ease;
    cursor: pointer;
    width: fix-content;
    position: relative;
    display: flex;
    justify-content: center;
}

.merki.val_1 {
    border: 1px solid #5ccc1a !important;
    background:#cff1c6 !important;
}

.merki.val_2 {
    border: 1px solid #c5e70b !important;
    background:#eaf8c2 !important;
}

.merki.val_3 {
    border: 1px solid #f1820b !important;
    background:#f5dfc2 !important;
}

.merki.val_4 {
    border: 1px solid #b80000 !important;
    background:#e6bebf !important;
}

.merking .likami .merki:hover {
    height:24px;
    width:24px;
    border: 1px solid #4296c2;
    border-radius: 12px;
    background:#8ff0ff;
    margin-top:-2px;
    margin-left:-2px;
}

.merking .likami .merki.hofud {
    position: absolute;
    left: calc(50% - 10px);
    top:10px;
}

.merking .likami .merki.munnhol {
    position: absolute;
    left: calc(50% - 10px);
    top:44px;
}

.merking .likami .merki.ondunarfaeri {
    position: absolute;
    left: calc(50% - 10px);
    top:90px;
}

.merking .likami .merki.meltingarfaeri {
    position: absolute;
    left: calc(50% - 10px);
    top:180px;
}

.merking .likami .merki.kynfaeri {
    position: absolute;
    left: calc(50% - 10px);
    top:224px;
}

.merking .likami .merki.hendurfaetur {
    position: absolute;
    left: calc(50% - 64px);
    top:180px;
}

.merking .likami .merki.vinstrihond {
    position: absolute;
    left: calc(50% - 64px);
    top:180px;
}

.merking .likami.kk .merki.vinstrihond {
    left: calc(50% - 72px);
}

.merking .likami .merki.haegrihond {
    position: absolute;
    left: calc(50% + 44px);
    top:180px;
}

.merking .likami.kk .merki.haegrihond {
    left: calc(50% + 51px);
}

.merking .likami .merki.hne {
    position: absolute;
    left: calc(50% - 10px);
    top:314px;
}

.merking .likami .merki.vinstrifotur {
    position: absolute;
    left: calc(50% - 29px);
    top:270px;
}

.merking .likami .merki.haegrifotur {
    position: absolute;
    left: calc(50% + 9px);
    top:270px;
    position: absolute;
}

.merking .likami .lidan {
    border:#efefef;
    background:#fff;
    font-size: 14px;
    width: 155px;
    text-align: left;
    padding:5px 5px 5px 10px;
    border-radius: 4px;
    border: 1px solid #199cc9;
    display:none;
}

.merking .likami .lidan .loka {
    position: absolute;
    top:-6px;
    right: -6px;
    width:18px;
    height:18px;
    border-radius: 10px;
    background-color: #000;
    color: #fff;
    text-align: center;
    line-height: 17px;
    cursor: pointer;
}

.merking .likami .lidan div { font-weight: bold; }

.merking .likami .lidan::before {
    content: "";
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #199cc9;
    position: absolute;
    left: -10px;
    top: 14px;
}

.merking .likami .lidan::after {
    content: "";
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #fff;
    position: absolute;
    left: -9px;
    top: 14px;
}

.merking .likami .lidan i { 
    font-size:23px;
    margin-right: 4px;
    cursor: pointer;
}

.merking .likami .lidan.hofud {
    position: absolute;
    left: calc(50% + 10px);
    top:-5px;
}

.merking .likami .lidan.munnhol {
    position: absolute;
    left: calc(50% + 10px);
    top:25px;
}

.merking .likami .lidan.kvidur {
    position: absolute;
    left: calc(50% + 10px);
    top:165px;
}

.merking .likami .lidan.kynfaeri {
    position: absolute;
    left: calc(50% + 10px);
    top:195px;
}


.merking .likami .lidan.ondun {
    position: absolute;
    left: calc(50% + 10px);
    top:75px;
}

.merking .likami .lidan.vistrifotur {
    position: absolute;
    left: calc(50% - 8px);
    top:255px;
}

.merking .likami .lidan.haegrifotur {
    position: absolute;
    left: calc(50% + 30px);
    top: 255px;
}

.merking .likami .lidan.vistrihond {
    position: absolute;
    left: calc(50% - 45px);
    top:165px;
}

.merking .likami .lidan.haegrihond {
    position: absolute;
    left: calc(50% + 65px);
    top: 165px;
}

.merking .likami .lidan.hne {
    position: absolute;
    left: calc(50% + 10px);
    top:300px;
}

.merki:hover .tip {
    opacity: 1;
    visibility: visible;
    pointer-events: initial;
}

.tip {
    position: absolute;
    padding: 8px;
    background: #000;
    color:#fff;
    width: auto;
    min-width: 100px;
    height: auto;
    text-align: center;
    border-radius: 8px;
    word-wrap: break-word;
    top: 100%;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.5s ease;
    font-size: 14px;
}

.tip::after {
    --trangle-length: 1em;
    content: "";
    position: absolute;
    width: var(--trangle-length);
    height: var(--trangle-length);
    background: #000;
    bottom: calc(100% - var(--trangle-length) / 2);
    left: calc(50% - var(--trangle-length) / 2);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.fa-face-smile {
    color:#199cc9;
}

.fa-face-expressionless {
    color:#5ccc1a;
}

.fa-face-frown-slight {
    color:#c5e70b;
}

.fa-face-anguished {
    color:#f1820b;
}

.fa-face-confounded {
    color:#b80000;
}

