
@media only screen and (max-width: 1240px) {
    .halli_left {
        right: calc(50% + 50px);
        width: calc(50% - 50px);
    }

    .halli_right {
        left: calc(50% + 50px);
        width: calc(50% - 50px);
    }
    
} 

@media only screen and (max-width: 980px) { 
    .halli_left {
        right: calc(10% + 0px);
        width: calc(90% - 0px);
    }

    .halli_right {
        left: calc(10% + 0px);
        width: calc(90% - 0px);
    }

    .greinar {
        width:100%;
    }

    .greinar article {
        padding-right: 0;
    }
    .greinar article.grein h1 {
        font-size: 36px;
        margin: 0px 0 5px;
    }

    .greinar article.grein .sidebar {
        display:none;
    }

    .greinar article.grein .main-layout {
        width: calc(100% - 0px);
        padding-left: 0px;
    }

    .hlidarstika {
        width: 100%;
    }

    .greinar article:nth-child(2), .greinar article {
        height: auto;
        width: 100%;
        padding-right: 0px;
        margin-bottom: 30px;
    }

    .greinar figure, .greinar article:nth-child(2) figure {
        height: 200px;
        width: 100%;
    }

}

@media only screen and (max-width: 560px) { 
    .halli_left {
        right: 0%;
        width: 100%;
    }

    .halli_right {
        left: 0%;
        width: 100%;
    }

    .spilarinn .hlusta {
        display:none !important;
    }

    .spilarinn .naest {
        display:none !important;
    }

    .spilarinn .iloftinu {
        width: 100% !important;
        border-right: 0px solid #ccdeeb !important;
    }

    .spilarinn .iloftinu .spilaritexti {
        width:160px !important;
    }

    .spilarinn .iloftinu .spilari {
        width: calc(100% - 160px) !important;        
    }

    .takkar {
        width: calc(100% - 0px) !important;
    }

    .timalina {
        width: calc(100% - 0px) !important;
    }

    .ibodi img {max-width: 160px;}

    .stjornufrettir {
        width: calc(100% - 13px);
        margin-left: 13px;
    }

    .forsidufrettir .frettalisti {
        width: calc(100% - 0px);
        padding: 10px;
    }

    .auglysing_kubbur {
        width: 100%;
        margin: 20px 0 40px;
    }

    .toplisti ol {
        padding: 0;
        width: 100%;
        margin: 0 ;
    }

    .ljosipunkturinn {
        height: 310px;
        width: calc(100% - 15px);
        margin-left: 15px;
    }

    .greinar article:nth-child(2), .greinar article {
        height: auto;
        width: 100%;
        padding-right: 0px;
        margin-bottom: 30px;
    }

    .greinar figure, .greinar article:nth-child(2) figure {
        height: 200px;
        width: 100%;
    }

    .adrargreinar article, .adrargreinar article:nth-child(2) {
        width:100%;
        height: auto;
    }

    .adrargreinar figure, .adrargreinar article:nth-child(2) figure {
        width:100%;
        height:160px;
        margin-right: 20px;
    }
}

@media only screen and (max-width: 440px) {  
    .fastirlidir article {
        display: inline-block;
        width: 100%;
        text-align: center;
        margin-bottom: 40px;
    }

    .forsidufrettir .frettalisti {
        width: 100%;
        margin: 0;
    }

    .toplisti ol, .toplisti ol:last-child {
        width: 100%;
        margin: 0;
    }
}