.merking {
    width:100%;
    position: relative;
    min-height:500px;
    text-align: center;
}

.merking .likami {
    height:450px;
    width:190px;
    background-image: url(../../img/kvk.svg);
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position:center;
    position: relative;
    margin: 0 auto;
}

.merking .likami.kk {
    background-image: url(../../img/kk.svg);
}

.merking .likami .merki {
    height:20px;
    width:20px;
    border: 1px solid #4296c2;
    border-radius: 10px;
    background:#eafcff;
    display: inline-block;
    transition: 0.3s ease;
    cursor: pointer;
    width: fix-content;
    position: relative;
    display: flex;
    justify-content: center;
}

.merki.val_1 {
    border: 1px solid #5ccc1a !important;
    background:#cff1c6 !important;
}

.merki.val_2 {
    border: 1px solid #c5e70b !important;
    background:#eaf8c2 !important;
}

.merki.val_3 {
    border: 1px solid #f1820b !important;
    background:#f5dfc2 !important;
}

.merki.val_4 {
    border: 1px solid #b80000 !important;
    background:#e6bebf !important;
}

.merking .likami .merki:hover {
    height:24px;
    width:24px;
    border: 1px solid #4296c2;
    border-radius: 12px;
    background:#8ff0ff;
    margin-top:-2px;
    margin-left:-2px;
}

.merking .likami .merki.hofud {
    position: absolute;
    left: calc(50% - 10px);
    top:10px;
}

.merking .likami .merki.munnhol {
    position: absolute;
    left: calc(50% - 10px);
    top:44px;
}

.merking .likami .merki.ondunarfaeri {
    position: absolute;
    left: calc(50% - 10px);
    top:90px;
}

.merking .likami .merki.meltingarfaeri {
    position: absolute;
    left: calc(50% - 10px);
    top:180px;
}

.merking .likami .merki.kynfaeri {
    position: absolute;
    left: calc(50% - 10px);
    top:224px;
}

.merking .likami .merki.hendurfaetur {
    position: absolute;
    left: calc(50% - 64px);
    top:180px;
}

.merking .likami .merki.vinstrihond {
    position: absolute;
    left: calc(50% - 64px);
    top:180px;
}

.merking .likami.kk .merki.vinstrihond {
    left: calc(50% - 72px);
}

.merking .likami .merki.haegrihond {
    position: absolute;
    left: calc(50% + 44px);
    top:180px;
}

.merking .likami.kk .merki.haegrihond {
    left: calc(50% + 51px);
}

.merking .likami .merki.hne {
    position: absolute;
    left: calc(50% - 10px);
    top:314px;
}

.merking .likami .merki.vinstrifotur {
    position: absolute;
    left: calc(50% - 29px);
    top:270px;
}

.merking .likami .merki.haegrifotur {
    position: absolute;
    left: calc(50% + 9px);
    top:270px;
    position: absolute;
}

.merking .likami .lidan {
    border:#efefef;
    background:#fff;
    font-size: 14px;
    width: 155px;
    text-align: left;
    padding:5px 5px 5px 10px;
    border-radius: 4px;
    border: 1px solid #199cc9;
    display:none;
}

.merking .likami .lidan .loka {
    position: absolute;
    top:-6px;
    right: -6px;
    width:18px;
    height:18px;
    border-radius: 10px;
    background-color: #000;
    color: #fff;
    text-align: center;
    line-height: 17px;
    cursor: pointer;
}

.merking .likami .lidan div { font-weight: bold; }

.merking .likami .lidan::before {
    content: "";
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #199cc9;
    position: absolute;
    left: -10px;
    top: 14px;
}

.merking .likami .lidan::after {
    content: "";
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #fff;
    position: absolute;
    left: -9px;
    top: 14px;
}

.merking .likami .lidan i { 
    font-size:23px;
    margin-right: 4px;
    cursor: pointer;
}

.merking .likami .lidan.hofud {
    position: absolute;
    left: calc(50% + 10px);
    top:-5px;
}

.merking .likami .lidan.munnhol {
    position: absolute;
    left: calc(50% + 10px);
    top:25px;
}

.merking .likami .lidan.kvidur {
    position: absolute;
    left: calc(50% + 10px);
    top:165px;
}

.merking .likami .lidan.kynfaeri {
    position: absolute;
    left: calc(50% + 10px);
    top:195px;
}


.merking .likami .lidan.ondun {
    position: absolute;
    left: calc(50% + 10px);
    top:75px;
}

.merking .likami .lidan.vistrifotur {
    position: absolute;
    left: calc(50% - 8px);
    top:255px;
}

.merking .likami .lidan.haegrifotur {
    position: absolute;
    left: calc(50% + 30px);
    top: 255px;
}

.merking .likami .lidan.vistrihond {
    position: absolute;
    left: calc(50% - 45px);
    top:165px;
}

.merking .likami .lidan.haegrihond {
    position: absolute;
    left: calc(50% + 65px);
    top: 165px;
}

.merking .likami .lidan.hne {
    position: absolute;
    left: calc(50% + 10px);
    top:300px;
}

.merki:hover .tip {
    opacity: 1;
    visibility: visible;
    pointer-events: initial;
}

.tip {
    position: absolute;
    padding: 8px;
    background: #000;
    color:#fff;
    width: auto;
    min-width: 100px;
    height: auto;
    text-align: center;
    border-radius: 8px;
    word-wrap: break-word;
    top: 100%;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.5s ease;
    font-size: 14px;
}

.tip::after {
    --trangle-length: 1em;
    content: "";
    position: absolute;
    width: var(--trangle-length);
    height: var(--trangle-length);
    background: #000;
    bottom: calc(100% - var(--trangle-length) / 2);
    left: calc(50% - var(--trangle-length) / 2);
    transform: rotate(45deg);
}

.fa-face-smile {
    color:#199cc9;
}

.fa-face-expressionless {
    color:#5ccc1a;
}

.fa-face-frown-slight {
    color:#c5e70b;
}

.fa-face-anguished {
    color:#f1820b;
}

.fa-face-confounded {
    color:#b80000;
}
