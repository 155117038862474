header.header {
    position:fixed;
    left:0px;
    top: 0px;
    height:100px;
    background: rgba(255,255,255,1);
    z-index: 10;
    width:calc(100% - 0px);
    padding: 0px;
    transition: 0.3s ease;
    margin-bottom: 0px;
    clear: both;
    transition: 0.2s ease;
    border-bottom: 1px solid rgba(225,225,225,0);
}

header.header i {
    font-size: 24px;
}

header.header h1.merki {
    float: left;
    display: inline-block;
    font-size:28px;
    margin:10px 0;
}

header.header h1.merki a {
    color:#303030;
    text-decoration: none;
}

h1.logo {
    font-weight: 900;
    float: left;
    position: relative;
    height: 54px;
    line-height: 54px;
    text-indent: 64px;
    z-index: 20;
}

h1.logo a {
    color:#000;
    text-decoration:none;
}

h1.logo::before {
    position: absolute;
    left: 0px;
    top: 0;
    height: 54px;
    width: 54px;
    background: #1297c7;
    border-radius: 27px;
    content: "";
    z-index: 1;
}

#hamborgari {
    width:36px;
    height:40px;
    position:fixed;
    margin:0 auto;
    -webkit-transition:.5s ease-in-out;
    -moz-transition:.5s ease-in-out;
    -o-transition:.5s ease-in-out;
    transition:.5s ease-in-out;
    cursor:pointer;
    float:left;
    top: 30px;
    right: 30px;
    z-index:103;
    display:none
}

#hamborgari:before {
    content:" ";
    height:50px;
    width:50px;
    border:5px solid rgba(200,200,200,0);
    -webkit-transition:.5s ease-in-out;
    -moz-transition:.5s ease-in-out;
    -o-transition:.5s ease-in-out;
    transition:.5s ease-in-out;
    margin-left:-6px;
    margin-top:-5px;
    display:block;
    -webkit-border-radius:50%;
    -moz-border-radius:50%;
    border-radius:50%
}

#hamborgari:hover:before {
    content:" ";
    height:60px;
    width:60px;
    border:5px solid rgba(200,200,200,.7);
    margin-left:-12px;
    margin-top:-10px
}

#hamborgari span {
    display:block;
    position:absolute;
    height:6px;
    width:36px;
    background:#333;
    border-radius:9px;
    opacity:1;
    left:0;
    -webkit-transition:.25s ease-in-out;
    -moz-transition:.25s ease-in-out;
    -o-transition:.25s ease-in-out;
    transition:.25s ease-in-out;
    top:8px
}

#hamborgari span:nth-child(1) {
    left:0
}

#hamborgari span:nth-child(2) {
    left:0;
    top:17px;
    width:36px
}

#hamborgari span:nth-child(3) {
    left:0;
    top:26px;
    width:36px
}

#hamborgari.open span {
    background:#df1a53
}

#hamborgari.open span:nth-child(1) {
    -webkit-transform:rotate(45deg);
    -moz-transform:rotate(45deg);
    -o-transform:rotate(45deg);
    transform:rotate(45deg);
    top:17px;
    left:2px;
    width:32px
}

#hamborgari.open span:nth-child(2) {
    background:#232323;
    width:0;
    left:15px
}

#hamborgari.open span:nth-child(3) {
    -webkit-transform:rotate(-45deg);
    -moz-transform:rotate(-45deg);
    -o-transform:rotate(-45deg);
    transform:rotate(-45deg);
    top:17px;
    left:2px;
    width:32px
}


ul.menu {
    padding: 0;
    margin:44px 0 0 0; 
    float:right;
    list-style: none;
    font-family: 'Ubuntu', sans-serif !important;
    font-weight: 600;
    line-height: 20px;
}

ul.menu li {
    float: left;
    margin: 0 10px;
}

ul.menu li a {
    color:#000;
    text-decoration: none;
    font-size:18px;
    transition: .30s ease-in-out;
}

ul.menu li a:hover, ul.menu li a.selected {
    color:#1297c7;
}

ul.menu li.btn, ul.menu li.btn_inn {
    float: left;
    margin: 0 0px 0 15px;
}

ul.menu li.divider {
    border-right: 1px solid #e9e9e9;
    padding-right: 30px;
    margin-right: 14px;
}


ul.menu li.btn a {
    border:1px solid #e9e9e9;
    display: block;
    padding:8px 16px;
    margin-top: -9px;
    border-radius: 12px;
    -webkit-transition: .30s ease-in-out;
    -moz-transition: .30s ease-in-out;
    -o-transition: .30s ease-in-out;
    transition: .30s ease-in-out;
}

ul.menu li.btn a:hover {
    text-decoration: none;
    background: #f9f9f9;
}

ul.menu li.btn_inn a {
    border:1px solid #107aa0;
    display: block;
    padding:8px 16px;
    margin-top: -9px;
    border-radius: 12px;
    background: #1297c7;
    color:#fff;
    transition: .30s ease-in-out;
}

ul.menu li.btn_inn a:hover {
    text-decoration: none;
    background: #107aa0;
}

ul.menu li.btn_inn.valid a {
    border:1px solid #df1a53;
    background: #df1a53;
}

ul.menu li.btn_inn.valid a:hover {
    border:1px solid #df1a53;
    background: #df1a53;
}

.fyrirsogn {
    max-width: 55%;
    font-size: 50px;
    font-weight: 900;
    margin: 0 0 20px 0;
}

.yfirfyrirsogn {
    max-width: 55%;
    font-size: 18px;
    font-weight: 300;
    color:#949494;
    margin: 100px 0 0 0;
}

.fyrirsogn span {color:#78e31c; }

.undirfyrirsogn {
    max-width: 55%;
    font-size: 18px;
}

.adgangur {
    color:#949494;
    margin: 40px 0;
}

.adgangur a {
    color:#1c78e3;
    font-weight: 500;
}

.takki {
    display: inline-block;
    padding:8px 20px;
    border-radius: 8px;
    border: 1px solid #107aa0;
    background: #1297c7;
    color:#fff !important;
    margin-right: 10px;
    font-family: 'Exo 2', sans-serif;
    font-weight: 600 !important;
    font-size: 20px;
    text-decoration: none !important;
    transition: .30s ease-in-out;
}

.takki:hover {
    text-decoration: none;
    background: #107aa0;
}

button.innskraning {
    background-color:transparent;
    border: none;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
}

button.innskraning:hover {
    color:#ab0b25;
}

@media only screen and (max-width: 980px) {
    header nav {
        display: none;
    }

    #hamborgari {
        display:block;
    }

    .vidhaekumigledinni {
        display: none;
    }

    h1.k100merki a {
        height: 47px;
        width: 110px;
    }
}