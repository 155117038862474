@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Ubuntu:wght@300;400;500;700;900&display=swap');

*, *::before, *::after { box-sizing: border-box; }
html { box-sizing: border-box; }
html, body { margin: 0; padding: 0; min-height: 100%; }

body {
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 21%, rgba(223,251,254,1) 100%);
    color:#242424;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    padding:80px 0px 0px;
    margin:0 auto;
    min-height: 100%;
    line-height: 1.2;
    -webkit-font-smoothing: antialiased !important;
	text-rendering: optimizeLegibility;
	font-feature-settings: "kern";
	-webkit-font-feature-settings: "kern";
	-moz-font-feature-settings: "kern";
	-moz-font-feature-settings: "kern=1";
}

a {
    color: #0073a0;
    text-decoration: underline #ccc;
}

a:hover {
    color: #04668c;
    text-decoration: underline #0073a0;
}

h1, h2, h3, h4, h5 {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 700;
}

main {
    width: calc(100% - 0px);
    margin: 0px auto;
    max-width:1240px;
    padding: 0 20px;
}

.wrapper {
    max-width: 1240px;
    width:100%;
    margin:0 auto;
    padding: 0 20px;
}

footer {
    position:fixed;
    left:0px;
    bottom: 0px;
    height:40px;
    line-height: 40px;
    background: rgba(255,255,255,0);
    z-index: 0;
    width:calc(100% - 0px);
    padding: 0px;
    transition: 0.3s ease;
    margin-bottom: 0px;
    clear: both;
    transition: 0.2s ease;
    border-top: 1px solid rgba(225,225,225,0);
}

footer ul {
    margin:0;
    list-style-type: none;
    padding:0px;
}

footer ul li {
    display:inline-block;
    margin-right: 15px;
}

.innskraningssida {
    margin: 0 auto;
    width:400px;
    min-height:410px;
    padding:20px;
    background-color: #fff;
    border-radius:20px;
    margin-top:30px;
}

.innskraningssida input {
    width: 100%;
    border: 1px solid #888988;
    padding:8px;
    font-size: 16px;
    margin: 5px 0;
    border-radius:6px;
    font-family: 'Open Sans', sans-serif;
}

.innskraningssida button {
    width: 100%;
    border: 1px solid #0095f9;
    background-color: #0095f9;
    color:#fff;
    padding:8px;
    font-size: 16px;
    margin: 5px 0;
    border-radius:6px;
    transition: 0.3s ease;
    cursor: pointer;
    font-family: 'Ubuntu', sans-serif;
}

.innskraningssida button:hover {
    background-color:#047ccd;
}

.innskraningssida .gleymt {
    margin:10px 0;
}

h3.login {
    font-size: 20px;
    font-weight:400;
    text-align: center;
    margin: 20px 0 0;
    color:#888988;
}

h2.login {
    font-size: 30px;
    font-weight:700;
    text-align: center;
    margin: 0px;
    color:#303030;
}

h2.nyskra {
    font-size: 30px;
    font-weight:700;
    text-align: center;
    margin: 0px 0 20px;
    color:#303030;
}

p.login {
    text-align: center;
    margin: 10px 0 30px;
}

.loginbtn {
    border: 2px solid #1297c7 !important;
    color:#fff;
    font-family: 'Ubuntu', sans-serif !important;
    font-weight: 700;
    background-color: #1297c7;
    padding:10px 0;
    transition: 0.3s ease;
    cursor: pointer;
    font-size: 20px;
}

.loginbtn:hover {
    background-color: #107aa0;

}

.brakethis {
    display: none;
  }


.buatilnotenda {
    margin-top:20px !important;
    cursor: pointer;
}

.itemfull {
    width:100%;
    background:#fff;
    margin:16px 0;
    border-radius: 8px;
    padding:24px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}

.itemfull .icon {
    height:60px;
    width:80px;
    text-align: center;
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;
}

.itemfull .info {
    min-height:60px;
    text-align: left;
    display: inline-block;
    vertical-align: top;
}

.itemfull .icon i {
    font-size: 60px;
}

.itemfull h4 {
    margin: 0;
}

.itemfull .moreInfo  {
    display:none;
}

.aukaupp {
    font-size:14px;
}

.question {
    width:100%;
    padding:10px;
}

.question label {
    display: inline-block;
}

.urgency {
    width:100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
    border: 1px solid #dedede;
    margin-bottom:20px;
}

.innskraning {
    float: right;
}

.addUrgency {
    float: right;
    display: inline-block;
    padding: 5px 10px;
    background:transparent;
    border:2px solid #0095f9;
    color:#333;
    font-size: 16px;
    text-decoration: none;
    border-radius:5px;
    transition: 0.3s ease;
}

.addUrgency:hover {
    color:#fff;
    background:#0095f9;
    text-decoration: none;
}

select, input {
    border:1px solid #999;
    background:rgba(255,255,255,0.7);
    border-radius: 5px;
    padding:5px 10px;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
}

.react-datetime-picker__wrapper {
    border:1px solid #999;
    background:rgba(255,255,255,0.7);
    border-radius: 5px;
}

.urgencies .content {
    float: right;
}

.urgency h3 {
    text-transform: uppercase;
    margin:0;
}

.urgency h4 {
    margin-top:0;
}

section .background_mynd {
    width: 45%;
    height: 500px;
    top: 0;
    right: 0;
    position: absolute;
    text-align: left;
    overflow: hidden;
    z-index: 1;
    text-align: center;
}

section .background_mynd img {
    max-width: 400px;
    margin: 100px auto 0;
}

.skra_mainwin {
    width: calc(100% - 420px);
    display: inline-block;
    vertical-align: top;
}

.skra_upplysingar {
    width: 420px;
    display: inline-block;
    vertical-align: top;
}

.buatilnotenda {
    border: 2px solid #107aa0;
    display: block;
    padding: 8px 16px;
    margin-top: -9px;
    border-radius: 8px;
    background: #fff;
    color: #107aa0;
    transition: .30s ease-in-out;
    cursor: pointer;
    font-family: 'Ubuntu', sans-serif !important;
    font-weight: 700;
    font-size: 20px;
    width:100%;
    text-align: center;
}

.buatilnotenda:hover {
    background: #107aa0;
    color:#fff;
}

h1.page-title {
    margin: 20px 0 10px;
    font-size: 28px;
}

h3.page-title {
    margin: 10px 0 10px;
    font-size: 18px;
    font-weight: 500;
}